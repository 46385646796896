// Bootstrap overrides
//
// Copy variables from `_variables.scss` to this file to override default values
// without modifying source files.

@import "functions";
@import "common";

@import "branding";

// Base colors
$orange:             $brand-orange;
$green:              tint-color(lighten($brand-green, 10%), 10%);
$pink:               $brand-pink;

$primary:            $brand-orange;
$secondary:          $gray-500;

// Component variables
$body-bg:            #1a1a1a;
$body-color:         $gray-200;

$link-color:         $link-blue-light;
$link-hover-color:   shade-color($link-color, 15%);

$text-muted:              $gray-500;

$border-color:            $gray-600;

$card-bg:                 $gray-900;
$card-border-color:       rgba($white, .125);

$dropdown-bg:                       $body-bg;
$dropdown-border-color:             $gray-600;

$dropdown-link-color:               $gray-100;
$dropdown-link-hover-color:         tint-color($dropdown-link-color, 10%);
$dropdown-link-hover-bg:            $gray-800;

$dropdown-link-active-color:        $black;
$dropdown-link-active-bg:           $primary;

$figure-caption-color:    $gray-400;

$input-bg:                $body-bg;
$input-border-color:      $border-color;
$input-disabled-bg:       $gray-800;

$form-check-input-border: 1px solid $border-color;

$form-select-indicator-color: $gray-200;

$list-group-bg:           $card-bg;
$list-group-color:        $gray-200;
$list-group-border-color: rgba($white, .125);
$list-group-active-color: $white;
$list-group-active-bg:    $gray-700;

$nav-tabs-border-color:             $gray-700;
$nav-tabs-link-hover-border-color:  $gray-700 $gray-700 $nav-tabs-border-color;
$nav-tabs-link-active-color:        $gray-300;
$nav-tabs-link-active-bg:           $body-bg;
$nav-tabs-link-active-border-color: $border-color $border-color $nav-tabs-link-active-bg;

@import "variables";

// Component variables that require default Bootstrap variables
$table-color:             $body-color;
$table-striped-color:     $white;
$table-striped-bg:        rgba($white, $table-striped-bg-factor);
$table-active-bg:         rgba($white, $table-active-bg-factor);
$table-hover-bg:          rgba($white, $table-hover-bg-factor);

@import "bootstrap";

// Custom variables
$btn-tinted-bg:                    $gray-900;
$btn-tinted-bg-active:             $gray-700;
$btn-tinted-border:                $gray-600;
$btn-tinted-color:                 $white;
$btn-tinted-color-danger:          $red;
$btn-tinted-color-danger-active:   lighten($btn-tinted-color-danger, 25%);
$btn-tinted-color-primary:         $brand-primary-200;
$btn-tinted-color-primary-active:  lighten($btn-tinted-color-primary, 25%);
$btn-tinted-color-success:         $green;
$btn-tinted-color-success-active:  lighten($btn-tinted-color-success, 25%);

$footer-from:             tint-color($body-bg, 10%);
$footer-to:               $body-bg;
$footer-link:             $gray-400;

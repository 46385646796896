@import "bootstrap/bootstrap.dark";
@import "includes";

:root {
	color-scheme: dark;
}

button {
	color: $body-color;
}
